import { useToast } from 'hooks';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCookie } from 'react-use';
import { mutationFn, USER_TOKEN_KEY } from 'utls';

export const useDelete = ({ type, id, action, refetchAnswers }) => {
  const [token] = useCookie(USER_TOKEN_KEY);
  const toast = useToast();
  const router = useRouter();
  return useMutation(
    () =>
      mutationFn({
        name: `delete-${action}`,
        params: { id },
        token
      }),
    {
      onSuccess: () => {
        if (type === 'question') {
          toast({
            title: 'عمليه ناجحه',
            description: 'تم الحذف بنجاح',
            status: 'success'
          });
          router.push('/');
        } else {
          toast({
            title: 'عمليه ناجحه',
            description: 'تم الحذف بنجاح',
            status: 'success'
          });
          refetchAnswers();
        }
      },
      onError: () => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );
};
