import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody
} from '@chakra-ui/react';
import { EditAnswerForm } from 'components/forms/EditAnswerForm';

export const EditAnswerModal = ({
  id,
  content,
  setEditAnswer,
  editAnswer,
  refetch,
  type
}) => {
  return (
    <Modal isOpen={editAnswer} onClose={() => setEditAnswer(false)}>
      <ModalOverlay />
      <ModalContent minW={{ base: 0, md: '35rem' }}>
        <ModalHeader
          color={'light.900'}
          textAlign='center'
          mt='6'
          fontSize={'h2'}
        >
          تعديل التعليق
        </ModalHeader>
        <ModalBody>
          <EditAnswerForm
            {...{ id, content, setEditAnswer, editAnswer, refetch, type }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
