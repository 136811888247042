import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import useTranslation from 'hooks/useTranslation';

type Item = {
  title: string;
  href: string;
};
interface Props {
  items: Item[];
}

export const GeneralSources: React.FC<Props> = ({ items }) => {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  const { T, locale } = useTranslation();

  return (
    <Accordion id={'sources'} allowToggle py='6'>
      <AccordionItem>
        <AccordionButton>
          <Box flex='1' textAlign='left'>
            <Text as='span'>{T(`general.sources.${locale}`)}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel>
          <UnorderedList>
            {items.map(({ title, href }, index) => (
              <ListItem key={index}>
                <Link href={href} isExternal>
                  {title}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
