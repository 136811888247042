import { Heading, LinkBox, Text, VStack } from '@chakra-ui/react';
import { Image } from 'components/shared';
import { NextChakraLinkOverlay } from 'components/wrappers';

import { htmlParser } from 'utls';

interface Props {
  fontSize?: string;
  hasNoReadMore?: boolean;
  imageSize: string;
}

export const PostItemBasic: React.FC<Props> = ({
  fontSize = 'h4',
  hasNoReadMore,
  imageSize = '(min-width: 62em) 49vw, 98vw',

  id,
  title,
  _embedded,
  link,
  type,
  date,
  thumbnail,
  thumb
}) => {
  const featured_media = _embedded?.['wp:featuredmedia']?.[0] ?? {
    url: thumbnail ?? thumb
  };
  return (
    <LinkBox as='article' role={'group'} rounded='md' h='full'>
      <VStack align={'stretch'} spacing={4} h='full'>
        <Image
          image={featured_media}
          ratio={16 / 9}
          rounded='md'
          boxShadow={'xl'}
          sizes={imageSize}
          // resizeWidth={244}
          blog={true}
        />
        <Heading as='span' fontSize={fontSize} color={'black'}>
          {link && (
            <NextChakraLinkOverlay href={link}>
              {title?.rendered ? (
                <Text
                  lineHeight={'1.5'}
                  fontWeight={'500'}
                  as='span'
                  noOfLines={2}
                  dangerouslySetInnerHTML={{ __html: title?.rendered }}
                />
              ) : (
                <Text
                  lineHeight={'1.5'}
                  fontWeight={'500'}
                  as='span'
                  noOfLines={2}
                >
                  {htmlParser(title)}
                </Text>
              )}
            </NextChakraLinkOverlay>
          )}
        </Heading>
        {/* {!hasNoReadMore && (
          <Box>
            <NextChakraButton
              variant={'ghost'}
              href={link}
              rightIcon={<Icon as={ArrowLeft} boxSize={'sm'} />}
            >
              اقرأ المزيد
            </NextChakraButton>
          </Box>
        )} */}
      </VStack>
    </LinkBox>
  );
};
