import {
  Avatar,
  Heading,
  HStack,
  Spacer,
  Stack,
  VStack
} from '@chakra-ui/react';
import { NextChakraLink } from 'components/wrappers';

import { User } from 'types/users';

export const UserCard: React.FC<User> = ({
  id,
  author,
  author_thumb,
  name,
  link,
  avatar_urls,
  blogAuthor,
  author_link
}: any) => {
  console.log(author, 'authorauthor');
  console.log(blogAuthor, 'blogAuthorblogAuthor');

  const imageSrc =
    avatar_urls?.[400] ??
    avatar_urls?.[250] ??
    avatar_urls?.[150] ??
    avatar_urls?.[96];

  return (
    <Stack
      direction={{ base: 'row' }}
      align={{ base: 'center' }}
      w='full'
      p='2'
      mt='2'
    >
      <VStack align={'stretch'}>
        <HStack>
          <NextChakraLink
            variant={'unstyled'}
            href={link ?? author_link}
            //  href={href}
          >
            <Avatar
              size='md'
              name={blogAuthor ? blogAuthor?.name : author}
              src={author_thumb ?? imageSrc}
              loading='lazy'
            />
          </NextChakraLink>

          <VStack align={'stretch'} spacing={1}>
            <Heading as='p' fontSize={'md'} lineHeight='none'>
              <NextChakraLink
                variant={'unstyled'}
                href={link ?? author_link}
                // href={href}
                color='black'
                fontWeight={'500'}
              >
                {blogAuthor ? blogAuthor?.name : author}
              </NextChakraLink>
            </Heading>
          </VStack>
        </HStack>
      </VStack>
      <Spacer />
    </Stack>
  );
};
