import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Text,
  VStack
} from '@chakra-ui/react';
import { Calendar, Calendar1, Microphone2 } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { getFullDate, htmlParser } from 'utls';
import { NextChakraLinkOverlay } from '../wrappers';
import { AuthorInfo, Image, DateText } from './';
import useTranslation from 'hooks/useTranslation';

const PostItem: React.FC = ({
  id,
  title,
  _embedded,
  link,
  type,
  date,
  thumbnail,
  thumb
}) => {
  const { T, locale } = useTranslation();

  const featured_media = _embedded?.['wp:featuredmedia']?.[0] ?? {
    url: thumbnail ?? thumb
  };
  const author = _embedded?.['author']?.[0];
  const isPodcast = type === 'podcast';

  const url = `${link}${isPodcast ? '?vol=1' : ''}`;

  // const is_bookmarked = false;
  // const [isBooked, setIsBooked] = useState(null);
  // useEffect(() => {
  //   if (is_bookmarked) setIsBooked(is_bookmarked);
  // }, [is_bookmarked]);

  return (
    <LinkBox as='article' pos={'relative'} role='group'>
      <HStack align={'stretch'}>
        <VStack align={'stretch'} spacing={4} w={2 / 3}>
          <Heading
            as='span'
            fontSize={{ base: 'h4', md: '1.125rem' }}
            fontWeight='medium'
          >
            <NextChakraLinkOverlay href={url}>
              {title?.rendered ? (
                <Text
                  lineHeight={'1.5'}
                  as='span'
                  dangerouslySetInnerHTML={{ __html: title.rendered }}
                />
              ) : (
                <Text lineHeight={'1.5'} as='span'>
                  {htmlParser(title)}
                </Text>
              )}
            </NextChakraLinkOverlay>
          </Heading>
          {date && (
            <HStack spacing={2}>
              {/* <Icon as={Calendar} boxSize={'1.125em'} color='#808080' /> */}
              <Text
                lineHeight={'1.5'}
                as='span'
                color='#808080'
                fontSize='15px'
              >
                {getFullDate(date, locale)}
              </Text>
            </HStack>
          )}

          {/* <AuthorInfo author={author} hasNoSocial /> */}
        </VStack>
        <Box w={1 / 3}>
          <NextChakraLinkOverlay href={url}>
            <Box pos={'relative'}>
              <Image
                image={featured_media}
                ratio={16 / 9}
                rounded='md'
                boxShadow={'xl'}
                sizes={'(min-width: 62em) 15vw, 30vw'}
                blog={true}
              />
              {isPodcast && (
                <Flex
                  alignItems={'flex-start'}
                  justifyContent='flex-end'
                  pos={'absolute'}
                  inset={0}
                  p={2}
                >
                  <Center
                    bgColor={'black'}
                    p={1}
                    // w='min-content'
                    rounded={'full'}
                  >
                    <Icon as={Microphone2} fontSize='3xl' color='white' />
                  </Center>
                </Flex>
              )}
            </Box>
          </NextChakraLinkOverlay>
        </Box>
      </HStack>

      {/* <>
      <LinkBox as='article' pos={'relative'} role='group' mt='4'>
        <HStack align={'stretch'}>
          <VStack
            align={'stretch'}
            justifyContent='center'
            spacing={4}
            w={2 / 3}
          >
            <Heading
              as='span'
              fontSize={{ base: 'h4', md: 'h3' }}
              fontWeight='medium'
            >
              <NextChakraLinkOverlay href={url}>
                <Text
                  lineHeight={'1.5'}
                  as='span'
                  dangerouslySetInnerHTML={{ __html: title.rendered }}
                />
              </NextChakraLinkOverlay>
            </Heading>

          </VStack>
          <Box w={1 / 3}>
            <NextChakraLinkOverlay href={url}>
              <Box pos={'relative'}>
                <Image
                  image={featured_media}
                  ratio={16 / 9}
                  rounded='md'
                  boxShadow={'xl'}
                  sizes={'(min-width: 62em) 15vw, 30vw'}
                />
                {isPodcast && (
                  <Flex
                    alignItems={'flex-start'}
                    justifyContent='flex-end'
                    pos={'absolute'}
                    inset={0}
                    p={2}
                  >
                    <Center
                      bgColor={'black'}
                      p={1}
                      // w='min-content'
                      rounded={'full'}
                    >
                      <Icon as={Microphone2} fontSize='3xl' color='white' />
                    </Center>
                  </Flex>
                )}
              </Box>
            </NextChakraLinkOverlay>
          </Box>
        </HStack>
      </LinkBox>
      <HStack
        align={'stretch'}
        spacing='2'
        mt='2'
        justifyContent={'space-between'}
      >
        <Box>
          {date && (
            <HStack align={'stretch'} spacing={2} justifyContent='stretch'>
              <Icon as={Calendar1} boxSize={'1.5em'} color='#808080' />
              <Text lineHeight={'1.5'} as='span' color='#808080'>
                {getFullDate(date)}
              </Text>
            </HStack>
          )}
        </Box>

        <Box>
          <BookmarkButton
            isBooked={isBooked}
            id={id}
            setIsBooked={setIsBooked}
          />
        </Box>
      </HStack>
    </> */}
    </LinkBox>
  );
};

export default PostItem;
