import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getExtraData, getRouteConfig } from 'ssr-data';
import { identifyPath } from 'utls';
import Parser from 'fast-xml-parser';
export const getRouteData = async (path, locale) => {
  const data = await identifyPath(path, locale);
  console.log(data, 'datadatadata');

  if (!data) {
    return null;
  }
  const { route_data } = data;
  const extraData = await getExtraData(data);
  const siteConfig = getRouteConfig(data);
  const comments =
    route_data?.object_type !== 'user' &&
    route_data?.object_type !== 'bookmarks'
      ? await getComments(route_data?.id)
      : [];

  const isBio =
    route_data?.type === 'bio' && route_data?.object_type === 'post';
  const slug = isBio && route_data?.acf?.person_name_ar;

  const RSSData = isBio
    ? await getRSSBio({
        url: `https://news.google.com/rss/search?q=intitle:"${slug}"&hl=ar&gl=SA&ceid=SA:ar`
      })
    : [];
  const ArageekRSSData = isBio
    ? await getRSSBio({
        url: `https://news.google.com/rss/search?q="site:arageek.com"intitle:"${slug}"&hl=ar&gl=SA&ceid=SA:ar`
      })
    : [];

  return {
    route_data: {
      ...route_data,
      ...extraData,
      comments: comments ?? [],
      RSSData: RSSData ?? [],
      ArageekRSSData: ArageekRSSData ?? []
    },
    siteConfig
  };
};

export const getComments = async id => {
  const data = await axios.get(
    `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/wp/v2/comments?post=${id}&per_page=100&page=1`
  );
  const comments = data?.data ?? [];
  return comments;
};

export const getRSSBio = async ({ url }) => {
  const parser = new Parser.XMLParser();
  const feedUrl = url;
  const response = await fetch(feedUrl);
  const text = await response.text();
  const parsed = parser.parse(text);

  return parsed?.rss?.channel;
};
