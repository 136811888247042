import { get } from 'lodash';
import { useRouter } from 'next/router';

import data from '../public/locales/data.json';
const useTranslation = () => {
  const router = useRouter();
  const T = (path: string) => {
    return get(data, path);
  };
  const locale = router?.locale;
  return { T, locale };
};
export default useTranslation;
