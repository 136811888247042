import { Box, HStack, Text } from '@chakra-ui/react';
import { useMenu } from 'hooks';
import React from 'react';
import { Slider } from './Slider';
import { NextChakraLink } from 'components/wrappers';
import useTranslation from 'hooks/useTranslation';

const MenuSlider = ({ homePage }) => {
  const MainMenu = useMenu('main-menu');
  const { T, locale } = useTranslation();
  console.log(homePage, 'homePagehomePage');

  if (!Array.isArray(MainMenu)) return null;
  return (
    <HStack
      align={'center'}
      spacing={0}
      width={'100%'}
      mt='0 !important'
      px='1'
      // py={{ base: '1', md: 0 }}
      justifyContent={'cneter'}
      position={{ base: 'sticky', md: 'initial' }}
      top={{ base: 'calc(100px - 50px)', md: '' }}
      zIndex='999'
      bg='#fff'
    >
      <Box width={{ base: '25%', sm: '20%', md: '12%', lg: '8%' }}>
        <Text fontSize={'lg'} fontWeight={'600'}>
          {T(`general.trend.${locale}`)}🔥
        </Text>
      </Box>

      <Box
        width={{ base: '75%', sm: '80%', md: '90%', lg: '90%' }}
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '&::-webkit-scrollbar-track': {
            display: 'none'
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none'
          }
        }}
        // boxShadow='0px 0px 1px -10px rgba(0,0,0,0.75), -10px 0px 15px -15px rgba(0,0,0,0.75)'
      >
        <Slider gap={4} hideBar={true}>
          {MainMenu?.map(item => (
            <Slide key={item.id} {...item} />
          ))}
        </Slider>
      </Box>
    </HStack>
  );
};

const Slide: React.FC = props => {
  const { url, title }: any = props;
  return (
    <Box
      pos='relative'
      flexGrow={0}
      flexShrink={0}
      flexBasis={{ base: '50%', sm: '35%', md: '16%' }}
    >
      <NextChakraLink
        borderRadius={'50px'}
        href={url}
        color='black'
        textDecoration='none'
        _hover={{
          textDecoration: 'none',
          backgroundColor: '#DFDABC',
          borderBottom: 'none'
          // color: '#fff'
        }}
        bg={'#f1efe2b3'}
        display={'flex'}
        justifyContent={'center'}
        py='2'
        px='3'
        sx={{
          ':before': {
            backgroundColor: 'unset !important'
          }
        }}
        fontWeight={'bold'}
      >
        {title}
      </NextChakraLink>

      {/* <NextChakraLink
        sx={{
          ':before': {
            backgroundColor: 'unset !important'
          }
        }}
        href={url}
        target='_blank'
        textDecoration='none'
        bg='#fff'
        color='#4F4F4F'
        _hover={{
          textDecoration: 'none',
          bg: '#fff',
          borderBottom: 'none',
          color: '#4169E3'
        }}
      >
        <Text fontSize={'17px'} fontWeight={'600'}>
          {title}
        </Text>
      </NextChakraLink> */}
    </Box>
  );
};
export default MenuSlider;
