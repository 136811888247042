import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'context';
import { useToast } from 'hooks';
import { useSession } from 'next-auth/react';
// import { useMutation, useQuery } from 'react-query';
// import { useCookie } from 'react-use';
import { mutationFn, USER_TOKEN_KEY } from 'utls';

export const useGetBookmarks = () => {
  const { token } = useAuth();

  const { data, refetch, isLoading } = useQuery([
    'get-bookmarks',
    {
      count: 100,
      namespace: 'arageek/v1'
    },
    token
  ]);

  return {
    data: data?.data,
    refetch,
    isLoading
  };
};

export const useBookmark = (id, callBack) => {
  const { data: session } = useSession();
  const toast = useToast();

  const { mutateAsync: mutateBookMark, isLoading } = useMutation(
    (params: any) =>
      mutationFn({
        name: params?.type === 'add' ? `add-bookmark` : `delete-bookmark`,
        params: { object_id: id, type: params?.type },
        token: session?.accessToken,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data, params }: any) => {
        console.log(params, 'params');
        toast({
          title: 'عمليه ناجحه',
          description:
            params?.type === 'add'
              ? 'تم الإضافة إلي مجلتي'
              : 'تم الحذف من مجلتي',
          status: 'success'
        });
        callBack({
          ...data,
          isBookMarked: params?.type === 'add' ? true : false
        });
      },
      onError: error => {
        console.log(error, 'error');
        // toast({
        //   title: 'خطأ',
        //   description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
        //   status: 'error'
        // });
      }
    }
  );

  const { mutateAsync: mutateIsBookMarked, isLoading: isBookedLoading } =
    useMutation(
      (params: any) =>
        mutationFn({
          name: 'is-bookmarked',
          params: { object_id: id },
          token: session?.accessToken,
          namespace: 'arageek/v1'
        }),
      {
        onSuccess: ({ data, params }: any) => {
          // console.log(params, 'params');
          // toast({
          //   title: 'عمليه ناجحه',
          //   description:
          //     params?.type === 'add'
          //       ? 'تم الإضافة إلي الحفظ'
          //       : 'تم الحذف من الحفظ',
          //   status: 'success'
          // });
          callBack(data);
        },
        onError: error => {
          console.log(error, 'errorerror');

          // toast({
          //   title: 'خطأ',
          //   description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          //   status: 'error'
          // });
        }
      }
    );

  return {
    mutateIsBookMarked,
    // refetchIsBookMarked,
    mutateBookMark,
    isBookedLoading,
    isLoading
  };
};
