import { Button, VStack } from '@chakra-ui/react';
import { Answer } from 'types/answers';
import { Comment } from 'types/comments';
import { AddComment } from './';
import { SingleComment } from './SingleComment';
import { ArrowCircleDown, ArrowLeft } from 'iconsax-react';
import { useState } from 'react';

interface Props extends Pick<Answer, 'comments'> {
  parent?: number;

  answerId: Answer['ID'];
  parentId?: Comment['ID'];
  isAddCommentOpen?: boolean;
  commentsRefetch?: any;
}

export const CommentsList: React.FC<Props> = ({
  answerId,
  parentId,
  comments,
  blogId,
  isAddCommentOpen,
  commentsRefetch,
  SSRComments
}) => {
  if (!comments || !Array.isArray(comments)) {
    return null;
  }

  const newComments = comments.filter(comment => comment?.parent === 0);
  const replies = comments.filter(comment => comment?.parent != 0);

  const newCommentData = newComments.forEach(parentComment => {
    const commentReplies = replies.filter(
      reply => parentComment.id == reply?.parent
    );
    parentComment.replies = commentReplies;
  });

  console.log(newCommentData, 'newCommentData');

  const [commentsCount, setCommentsCount] = useState(5);

  return (
    <VStack
      spacing={4}
      align='stretch'
      borderColor='white'
      paddingLeft={0}
      borderLeftWidth={0}
      pb='4'
    >
      <AddComment
        parentId={parentId}
        answerId={answerId}
        itemsize={'md'}
        blogId={blogId}
      />

      {Array.isArray(newComments) &&
        newComments
          .slice(0, commentsCount)
          .map(item => (
            <SingleComment
              key={item.id}
              {...item}
              avatarSize={'md'}
              answerId={answerId}
              commentsRefetch={commentsRefetch}
              blogId={blogId}
            />
          ))}

      {commentsCount < newComments?.length && (
        <Button
          rightIcon={<ArrowLeft />}
          onClick={() => setCommentsCount(5 + commentsCount)}
          color='dark.500'
        >
          المزيد{' '}
        </Button>
      )}
    </VStack>
  );
};
