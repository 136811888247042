//gist.github.com/ediamin/19ef0a4b0bff2dbfd7676c916d7dcd2a

const _autop_newline_preservation_helper = matches => {
	return matches[0].replace('\n', '<WPPreserveNewline />');
};

export const wpautop = (txt = '', br = true) => {
	var pre_tags = {};
	if (txt.trim() === '') {
		return '';
	}

	txt = txt + '\n'; // just to make things a little easier, pad the end
	if (txt.indexOf('<pre') > -1) {
		var txt_parts = txt.split('</pre>');
		var last_txt = txt_parts.pop();
		txt = '';
		txt_parts.forEach(function (txt_part, index) {
			var start = txt_part.indexOf('<pre');

			// Malformed html?
			if (start === -1) {
				txt += txt_part;
				return;
			}

			var name = '<pre wp-pre-tag-' + index + '></pre>';
			pre_tags[name] = txt_part.substr(start) + '</pre>';
			txt += txt_part.substr(0, start) + name;
		});

		txt += last_txt;
	}

	txt = txt.replace(/<br \/>\s*<br \/>/, '\n\n');

	// Space things out a little
	var allblocks =
		'(?:table|thead|tfoot|caption|col|colgroup|tbody|tr|td|th|div|dl|dd|dt|ul|ol|li|pre|form|map|area|blockquote|address|math|style|p|h[1-6]|hr|fieldset|legend|section|article|aside|hgroup|header|footer|nav|figure|figcaption|details|menu|summary)';
	txt = txt.replace(new RegExp('(<' + allblocks + '[^>]*>)', 'gmi'), '\n$1');
	txt = txt.replace(new RegExp('(</' + allblocks + '>)', 'gmi'), '$1\n\n');
	txt = txt.replace(/\r\n|\r/, '\n'); // cross-platform newlines

	if (txt.indexOf('<option') > -1) {
		// no P/BR around option
		txt = txt.replace(/\s*<option'/gim, '<option');
		txt = txt.replace(/<\/option>\s*/gim, '</option>');
	}

	if (txt.indexOf('</object>') > -1) {
		// no P/BR around param and embed
		txt = txt.replace(/(<object[^>]*>)\s*/gim, '$1');
		txt = txt.replace(/\s*<\/object>/gim, '</object>');
		txt = txt.replace(/\s*(<\/?(?:param|embed)[^>]*>)\s*/gim, '$1');
	}

	if (txt.indexOf('<source') > -1 || txt.indexOf('<track') > -1) {
		// no P/BR around source and track
		txt = txt.replace(/([<\[](?:audio|video)[^>\]]*[>\]])\s*/gim, '$1');
		txt = txt.replace(/\s*([<\[]\/(?:audio|video)[>\]])/gim, '$1');
		txt = txt.replace(/\s*(<(?:source|track)[^>]*>)\s*/gim, '$1');
	}

	txt = txt.replace(/\n\n+/gim, '\n\n'); // take care of duplicates

	// make paragraphs, including one at the end
	var txts = txt.split(/\n\s*\n/);
	txt = '';
	txts.forEach(function (tinkle) {
		txt += '<p>' + tinkle.replace(/^\s+|\s+$/g, '') + '</p>\n';
	});

	txt = txt.replace(/<p>\s*<\/p>/gim, ''); // under certain strange conditions it could create a P of entirely whitespace
	txt = txt.replace(/<p>([^<]+)<\/(div|address|form)>/gim, '<p>$1</p></$2>');
	txt = txt.replace(new RegExp('<p>s*(</?' + allblocks + '[^>]*>)s*</p>', 'gmi'), '$1', txt); // don't txt all over a tag
	txt = txt.replace(/<p>(<li.+?)<\/p>/gim, '$1'); // problem with nested lists
	txt = txt.replace(/<p><blockquote([^>]*)>/gim, '<blockquote$1><p>');
	txt = txt.replace(/<\/blockquote><\/p>/gim, '</p></blockquote>');
	txt = txt.replace(new RegExp('<p>s*(</?' + allblocks + '[^>]*>)', 'gmi'), '$1');
	txt = txt.replace(new RegExp('(</?' + allblocks + '[^>]*>)s*</p>', 'gmi'), '$1');

	if (br) {
		txt = txt.replace(/<(script|style)(?:.|\n)*?<\/\\1>/gim, _autop_newline_preservation_helper); // /s modifier from php PCRE regexp replaced with (?:.|\n)
		txt = txt.replace(/(<br \/>)?\s*\n/gim, '<br />\n'); // optionally make line breaks
		txt = txt.replace('<WPPreserveNewline />', '\n');
	}

	txt = txt.replace(new RegExp('(</?' + allblocks + '[^>]*>)s*<br />', 'gmi'), '$1');
	txt = txt.replace(/<br \/>(\s*<\/?(?:p|li|div|dl|dd|dt|th|pre|td|ul|ol)[^>]*>)/gim, '$1');
	txt = txt.replace(/\n<\/p>$/gim, '</p>');

	if (Object.keys(pre_tags).length) {
		txt = txt.replace(new RegExp(Object.keys(pre_tags).join('|'), 'gi'), function (matched) {
			return pre_tags[matched];
		});
	}

	return txt;
};
