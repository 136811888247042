import React, { useEffect, useRef, useState } from 'react';

import { Button, Box, Icon, VStack, Text } from '@chakra-ui/react';

import { useGoogleLogin } from '@react-oauth/google';
import { useToast } from 'hooks';
import { useAuth } from 'context';
import { Google } from 'iconsax-react';
import { BsGoogle } from 'react-icons/bs';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import useTranslation from 'hooks/useTranslation';

export const GoogleAuthButton = ({ title, type }) => {
  const googlebuttonref = useRef(null);
  const toast = useToast();
  const { T, locale } = useTranslation();
  const { mutateGoogleAuth } = useAuth();

  const router = useRouter();
  console.log(router.asPath, 'googlegoogle');

  const [backUrl, setBackUrl] = useState('');
  useEffect(() => {
    if (router.asPath) setBackUrl(router.asPath);
  }, [router.asPath]);

  const login = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        const access_token = String(codeResponse.access_token);
        console.log(codeResponse, 'codeResponse');
        // await mutateGoogleAuth({
        //   access_token,
        //   type: 'button'
        // });
        // await signIn('google', {
        //   callbackUrl: `/`,
        //   redirect: true
        // });

        await signIn('google', {
          redirect: true,
          callbackUrl: backUrl
        });

        // console.log(response, 'handleLoginhandleLogin');
        // if (response?.error) console.log(response?.error);
        // if (response) console.log(response, 'resssss');
      } catch (e) {
        console.error(e);
      }
    },
    onError: error => {
      toast({
        title: T(`general.toastMessages.error.${locale}`),
        description: T(`general.toastMessages.error_message.${locale}`),
        status: 'error'
      });
    }
    // flow: 'auth-code'
  });

  return (
    <>
      <VStack align={'center'} spacing='2'>
        <Button
          variant={'none'}
          // backgroundColor={'dark.100'}
          color='#fff'
          sx={{
            span: {
              marginInlineEnd: '0rem'
            }
          }}
          leftIcon={
            <Icon
              as={BsGoogle}
              fontSize='18px'
              marginInlineEnd={'0rem !important'}
            />
          }
          ref={googlebuttonref}
          onClick={() => login()}
          colorScheme='blackAlpha'
          backgroundColor={'black'}
          width='40px'
          height={'40px'}
        />

        <Text>{title}</Text>
      </VStack>
    </>
  );
};
