import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Form, HiddenInput, SubmitButton } from 'components/forms-elements';
import { useToast } from 'hooks';
import { validationRules } from 'utls';

import { useAuth, useQueryKeys } from 'context';
import { FormikConfig } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { mutationFn } from 'utls';
import * as Yup from 'yup';

import { useState } from 'react';
import useTranslation from 'hooks/useTranslation';

const validationSchema = Yup.object({
  question_id: validationRules.string,
  content: validationRules.string.min(10, 'يجب أن يكون ٥٠ حرف على الأقل')
});

interface Props {
  id: string;
}

export const AddAnswerForm: React.FC<Props> = ({ id }) => {
  const { T, locale } = useTranslation();
  const { token } = useAuth();
  const queryKeys = useQueryKeys();
  const { refetch } = useQuery(queryKeys.answers);
  const toast = useToast();

  const { mutateAsync: mutateAddAnswer } = useMutation(
    params => mutationFn({ name: `add-answer`, params, token }),
    {
      onSuccess: ({ data, params }) => {
        refetch();
        toast({
          title: T(`general.toastMessages.added.${locale}`),
          description: '',
          status: 'success'
        });
      },
      onError: error => {
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description: T(`general.toastMessages.error_message.${locale}`),
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      mutateAddAnswer(values);
      resetForm();
    } catch (error) {}
    resetForm();
  };

  const [mentionedUser, setMentionedUser] = useState();

  const [value, setValue] = useState('');
  function fetchUsers(query, callback) {
    if (!query) return;
    fetch(`https://jsonplaceholder.typicode.com/users?q=${query}`, {
      json: true
    })
      .then(res => res.json())

      // Transform the users to what react-mentions expects
      .then(res => res.map(user => ({ display: user.username, id: user.name })))

      .then(callback);
  }

  /* eslint-disable import/no-anonymous-default-export */
  const mentionsInputStyle = {
    control: {
      backgroundColor: '#fff',
      fontSize: 16
      // fontWeight: 'normal',
    },

    '&multiLine': {
      control: {
        fontFamily: 'monospace',
        minHeight: 60
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent'
      },
      input: {
        padding: 9,
        border: '1px solid silver'
      }
    },

    '&singleLine': {
      display: 'inline-block',
      width: 180,

      highlighter: {
        padding: 1,
        border: '2px inset transparent'
      },
      input: {
        padding: 1,
        border: '2px inset'
      }
    },

    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 16
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5'
        }
      }
    }
  };

  const mentionStyle = {
    backgroundColor: '#cee4e5'
  };

  return (
    <Form
      initialValues={{
        question_id: id,
        content: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <VStack align={'stretch'} spacing={4}>
        <HiddenInput name='question_id' />
        {/* <RichEditor name={'content'} type="full" /> */}
        <Box dir='ltr'>
          {/* <RichTextField
            defaultValue={null}
            id={'content'}
            name={'content'}
          ></RichTextField> */}
          {/* <MentionsInput
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={mentionsInputStyle}
            placeholder="Mention any JsonPlaceholder username by typing `@` followed by at least one character"
            a11ySuggestionsListLabel={'Suggested JsonPlaceholder username for mention'}>
            <Mention
              displayTransform={(id) => `@${id}`}
              trigger="@"
              data={fetchUsers}
              style={mentionStyle}
            />
          </MentionsInput> */}
        </Box>
        <Text color={'dark.600'} fontSize='sm'>
          احرص على أن تكون إجابتُك أصليّة وغير منقولة أو منسوخة! اجعلها وافيّة
          وغنيّة وموضوعيّة. حافظ على سُمعتِك الرّقمية ولا تتنمّر ولا تبتذل
          واحترم الأعضاء والقراء. للمزيد اطلع على شروط وأحكام النّشر في مجتمع
          أراجيك.
        </Text>
        <HStack justify={'flex-end'}>
          <SubmitButton>إضافة</SubmitButton>
        </HStack>
      </VStack>
    </Form>
  );
};
