import {
  Center,
  Collapse,
  Divider,
  HStack,
  Spacer,
  Spinner,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
// import { InterestsRenderer } from 'components/interests';
import { ActionsMenu } from 'components/shared';
import { ReportMenu } from 'components/shared/ReportMenu';
import { UserCard } from 'components/users/UserCard';
import { useAuth } from 'context';
import { useComments, useCurrentUser } from 'hooks';
import { useEffect, useState } from 'react';
import { Answer } from 'types/answers';
import { Question } from 'types/questions';
// import type { Answer, Question } from 'types';
import { CommentsList } from '../';
import { QuestionTitle } from '../shared';
import { ActionBar, AnswerContent } from './components';

interface Props {
  hasNoAuthor?: boolean;
  isArticle?: boolean;
  refetch?: () => void;
  myAnswer?: boolean;
}

export const SingleAnswer: React.FC<
  Props &
    Answer &
    Pick<Question, 'categories' | 'title' | 'slug' | 'is_answered'>
> = ({
  ID,
  author,
  content,
  date,
  votes,
  title,
  categories,
  is_answered,
  slug,
  isArticle,
  hasNoAuthor,
  refetch,
  userSlug,
  answer_votes,
  answer,
  myAnswer,
  comments_count,
  current_vote,
  bg
}) => {
  console.log(answer, 'answeranswer');
  console.log(ID, 'IDID');
  const { isOpen, onOpen: onCommentsOpen } = useDisclosure();

  const currentUser = useCurrentUser();
  const {
    isLoading,
    refetch: commentsRefetch,
    comments
  } = useComments(answer?.ID ?? ID);
  const { slug: cuurentUserSlug } = currentUser;
  const { slug: authorSlug } = author;

  const [editAnswer, setEditAnswer] = useState(false);

  const [votesData, setVotes] = useState<{
    upvotes?: number | string;
    downvotes?: number | string;
    currentVote?: string;
  }>({
    upvotes: 0,
    downvotes: 0,
    currentVote: ''
  });

  console.log(author, 'author');
  const questionUrl = is_answered ? `/${slug}` : `/unanswered/${slug}`;

  const callBack = data => {
    setVotes({ ...data?.votes, currentVote: data?.actionType });
  };
  useEffect(() => {
    if (isOpen) {
      commentsRefetch();
    }
  }, [isOpen, commentsRefetch]);

  useEffect(() => {
    if (answer?.votes)
      setVotes({ ...answer?.votes, currentVote: answer?.current_vote });
    if (votes) setVotes({ ...votes, currentVote: current_vote });
  }, [answer?.votes, votes, answer?.current_vote, current_vote]);

  console.log(votesData, 'votesData');

  return (
    <VStack
      id={ID}
      as={isArticle ? 'article' : undefined}
      align={'stretch'}
      spacing={4}
      p={4}
      bg={bg ?? '#fff'}
      borderWidth={bg ? 0 : 2}
      rounded={'lg'}
    >
      <HStack align={'flex-start'}>
        <VStack spacing={4} align={'stretch'}>
          {/* {categories && <InterestsRenderer type="link" items={categories} />} */}
          {title && (
            <QuestionTitle title={title} hasUrl questionUrl={questionUrl} />
          )}
          {!hasNoAuthor && (
            <UserCard
              hasNoFollowButton
              author={myAnswer ? answer?.author : author}
            />
          )}
        </VStack>
        <Spacer />
        {authorSlug === cuurentUserSlug ? (
          <ActionsMenu
            type='answer'
            id={ID}
            editAnswer={editAnswer}
            setEditAnswer={setEditAnswer}
            content={myAnswer ? answer?.content : content}
            setIsEditQuestion={() => {}}
            setIsOpenAskModal={() => {}}
          />
        ) : (
          ''
        )}
      </HStack>

      <AnswerContent content={answer?.content ? answer?.content : content} />

      <ActionBar
        {...{
          ID,
          comments_count: answer?.comments_count ?? comments_count,
          current_vote: votesData?.currentVote,
          onCommentsOpen,
          votes: votesData,
          title,
          userSlug,

          answer_id: answer?.ID ?? ID,
          myAnswer
        }}
        questionUrl={questionUrl}
        refetch={refetch}
        dataCallBack={callBack}
      />

      <Collapse in={isOpen}>
        <VStack spacing={4} align='stretch'>
          <Divider />
          {isLoading && (
            <Center>
              <Spinner size='lg' />
            </Center>
          )}

          <CommentsList
            {...{ answerId: answer?.ID ?? ID, comments, commentsRefetch }}
          />
        </VStack>
      </Collapse>
    </VStack>
  );
};
