import {
  Box,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  HStack,
  Icon,
  Spacer
} from '@chakra-ui/react';
import { ArrowLeft2 } from 'iconsax-react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { getPathInfo } from 'utls';

export type NextChakraButtonProps = PropsWithChildren<
  NextLinkProps & Omit<ChakraButtonProps, 'as'>
>;

//  Has to be a new component because both chakra and next share the `as` keyword
export const NextChakraButton = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  children,
  hasChildItems,
  ...chakraProps
}: NextChakraButtonProps) => {
  const { asPath } = useRouter();

  const { type, url } = getPathInfo(href);

  const isActive = asPath === url;

  return (
    <ChakraButton
      className={isActive ? 'active' : ''}
      as={hasChildItems ? ChakraButton : NextLink}
      passHref={hasChildItems ? false : true}
      // passHref={true}
      href={hasChildItems ? '' : url}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={false}
      {...chakraProps}
    >
      <HStack>
        {children}
        {hasChildItems && (
          <Box
            display={{
              base: 'inline',
              lg: 'none',
              xl: 'inline'
            }}
          >
            <Spacer />
            <Icon as={ArrowLeft2} mt='2' />
          </Box>
        )}
      </HStack>
    </ChakraButton>
  );
};
