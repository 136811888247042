import {
  AspectRatio,
  Box,
  Button,
  Center,
  HStack,
  SimpleGrid,
  VisuallyHiddenInput,
  Image,
  VStack,
  Text,
  Icon
} from '@chakra-ui/react';

import { Form, Input, SubmitButton } from 'components/forms-elements';
import { ALLOWEDFILETYPE } from 'configs/imageTypes';
import { useAuth } from 'context';
import { FormikConfig } from 'formik';
import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { mutationFn, validationRules } from 'utls';
import * as Yup from 'yup';
import { Gallery } from 'iconsax-react';

const inputStyle = {
  border: '0.5px solid #DDD',
  borderRadius: 'md',
  paddingInlineStart: '2',
  fontSize: 'sm',
  boxShadow: 'unset !important',
  backgroundColor: 'dark.100'
};

export const CreateProfileForm = ({
  children,
  firstname,
  lastname,
  slug,
  avatar_url
}: any) => {
  const { token } = useAuth();
  const { mutateUpdateUser, refetchUser } = useAuth();
  const avatarRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState();
  const [isImagePicked, setIsImagePicked] = useState(false);
  const [imagePreview, setImagePreview] = useState(avatar_url);

  const validationSchema = Yup.object({
    avatar: validationRules.stringOptional,
    fname: validationRules.string,
    lname: validationRules.string
  });

  const { mutateAsync: mutateAddAvatar } = useMutation(params =>
    mutationFn({
      name: `add-user-avatar`,
      namespace: 'arageek/v1',
      params,
      token
    })
  );

  const changeHandler = event => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedImage(event.target.files[0]);
      setIsImagePicked(true);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      const { avatar, careerType, ...rest } = values;
      await mutateUpdateUser(rest);
      await mutateAddAvatar({ avatar: selectedImage });
      await refetchUser();
    } catch (error) {
      // resetForm();
    }
  };

  return (
    <Form
      initialValues={{
        avatar: '',
        fname: firstname,
        lname: lastname
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      hasDevTools={false}
    >
      <SimpleGrid gap={8}>
        <SimpleGrid gap={6}>
          <VStack
            align={'stretch'}
            gap={0}
            spacing='4'
            alignItems='center'
            mb='6'
          >
            <Box position={'relative'}>
              <VisuallyHiddenInput
                type='file'
                name='avatar'
                accept={ALLOWEDFILETYPE?.join(',')}
                opacity='0'
                position={'absolute'}
                w='full'
                h='full'
                zIndex={'10'}
                cursor='pointer'
                ref={avatarRef}
                onChange={changeHandler}
                _first={{ clip: 'initial' }}
              />
              <AspectRatio
                ratio={1}
                backgroundColor={'gray.100'}
                transitionProperty='common'
                transitionDuration='normal'
                transitionTimingFunction={'ease-in-out'}
                w='100px'
                h='100px'
                borderRadius='full'
                overflow={'hidden'}
              >
                <Center>
                  {imagePreview && (
                    <Image
                      src={imagePreview}
                      alt='image'
                      w='full'
                      objectFit={'cover'}
                    />
                  )}
                  {!imagePreview && (
                    <Icon as={Gallery} pos='absolute' fontSize={'25px'} />
                  )}
                </Center>
              </AspectRatio>
            </Box>
            <Text color='#4F4F4F' fontSize={'sm'}>
              أضف صورة
            </Text>
          </VStack>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={6} mb='6'>
            <Input
              name='fname'
              label='الإسم الأول'
              autoComplete='given-name'
              isRequired
              inputProps={{ ...inputStyle }}
            />
            <Input
              name='lname'
              label='إسم العائلة'
              autoComplete='family-name'
              isRequired
              inputProps={{ ...inputStyle }}
            />
          </SimpleGrid>

          <Input
            name='username'
            label='إسم المستخدم'
            autoComplete='username'
            isRequired
            inputProps={{ ...inputStyle }}
            text='الرجاء استخدام الأحرف الإنجليزية لإنشاء اسم حساب في الموقع، مثال: samer'
          />
        </SimpleGrid>
        <SubmitButton colorScheme={'dark'}>متابعة</SubmitButton>
      </SimpleGrid>
    </Form>
  );
};
