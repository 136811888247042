import { VStack } from '@chakra-ui/react';

import { useMutation } from '@tanstack/react-query';
import { Form, Input, SubmitButton, Textarea } from 'components/forms-elements';
import { useAuth } from 'context';
import { showLoginModal, useLayoutContext } from 'context/layout';
import { FormikConfig } from 'formik';
import { useToast } from 'hooks';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useTranslation from 'hooks/useTranslation';
import { useSession } from 'next-auth/react';
import { mutationFn, validationRules } from 'utls';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  author_name: validationRules.string,
  author_email: validationRules.email,
  content: validationRules.string.min(50, 'يجب أن يكون على الأقل ٥٠ محرف')
});

export const AddCommentForm: React.FC = ({ id, callback, onClose }) => {
  const toast = useToast();
  const { T, locale } = useTranslation();
  // const { token } = useAuth();
  const { data: session } = useSession();
  const { dispatch } = useLayoutContext();

  const { mutateAsync: mutateAddComment } = useMutation(
    params => mutationFn({ name: `comments`, params }),
    {
      onSuccess: ({ data, params }) => {
        console.log(data, 'datadatadata');
        if (data.status === 'hold') {
          toast({
            title: T(`general.toastMessages.thanks.${locale}`),
            description: T(
              `general.toastMessages.comment_under_review.${locale}`
            ),
            status: 'warning'
          });
          callback?.();
        } else {
          toast({
            title: T(`general.toastMessages.thanks.${locale}`),
            description: T(
              `general.toastMessages.comment_added_successfully.${locale}`
            )
          });
          callback?.();
        }
      },
      onError: error => {
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description: T(`general.toastMessages.error_message.${locale}`),

          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (session?.accessToken) {
        await mutateAddComment({ post: id, ...values });
        resetForm();
      } else {
        toast({
          title: T(`general.toastMessages.alert.${locale}`),
          description: T(`general.toastMessages.login_first.${locale}`),
          status: 'warning',
          duration: 2000
        });
        onClose();
        dispatch(showLoginModal());
      }
    } catch (error) {
      resetForm();
    }
  };

  const currenUser = useCurrentUser();
  const { fullname, email } = currenUser;
  console.log(currenUser, 'currenUser');

  return (
    <Form
      initialValues={{
        author_name: currenUser?.fullname ? `${fullname}` : '',
        author_email: currenUser?.email ? `${email}` : '',
        content: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      hasDevTools={true}
      enableReinitialize
    >
      <VStack align={'stretch'} spacing={8}>
        <VStack align={'stretch'} spacing={4}>
          <Input name='author_name' label='الأسم' />
          <Input name='author_email' label='البريد الالكتروني' />

          <Textarea name='content' label='تعليقك اللطيف...' />
        </VStack>
        <VStack align={'stretch'} spacing={4}>
          <SubmitButton>إرسال</SubmitButton>
        </VStack>
      </VStack>
    </Form>
  );
};
