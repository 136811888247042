import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  VStack
} from '@chakra-ui/react';

import { useComments, useToast } from 'hooks';
import { Message } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { BACKENDURL, htmlParser } from 'utls';
import axios from 'redaxios';
import { useRouter } from 'next/router';
import { showLoginModal, useLayoutContext } from 'context/layout';
import { CommentsList } from 'components/questions';
import { useSession } from 'next-auth/react';
import useTranslation from 'hooks/useTranslation';
export const PostMetaFooter = ({ title, link, id, SSRComments }) => {
  const { T, locale } = useTranslation();
  const toast = useToast();
  // const onCommentsOpen = useComments(id);
  const [email, setEmail] = useState('');
  const { dispatch } = useLayoutContext();
  const { data: session } = useSession;

  const { push, asPath } = useRouter();

  const subscribe = async () => {
    await axios
      .post(`${BACKENDURL}/arageek/v1/newsletter-subscribe`, { email })
      .then(res => {
        toast({
          title: T(`general.toastMessages.thanks.${locale}`),
          description: T(`general.toastMessages.subscribed_arageek.${locale}`)
        });
        setEmail('');
      })
      .catch(err => {
        // Handle error
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description: T(`general.toastMessages.error_message.${locale}`),
          status: 'error'
        });
      });
  };

  const submit = email => {
    if (!email) {
      toast({
        title: T(`general.toastMessages.error.${locale}`),
        description: T(`general.toastMessages.add_email.${locale}`),
        status: 'error'
      });
    } else {
      subscribe();
    }
  };

  const { isLoading, refetch: commentsRefetch, comments } = useComments(id);

  useEffect(() => {
    commentsRefetch();
  }, [id, session?.accessToken]);
  return (
    <>
      <VStack
        align={'stretch'}
        spacing='4'
        borderTop='1px '
        borderBottom='1px '
        borderColor={'gray.300'}
        py='8'
        my='8'
      >
        <Text fontSize={'lg'} fontWeight='600'>
          {T(`general.metaFooter.our_best.${locale}`)}
        </Text>
        <HStack spacing={4} pt='4'>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={T(`general.metaFooter.email.${locale}`)}
            size='lg'
            bg='#fff'
            p='4'
            borderRadius={'md'}
            border='0.5px solid #DDD'
            paddingInlineStart='2'
            fontSize='md'
          />
          <Button colorScheme={'dark'} onClick={() => submit(email)}>
            {T(`general.metaFooter.register.${locale}`)}
          </Button>
        </HStack>
        <Text fontSize={'13px'} color='dark.600'>
          {' '}
          {T(`general.metaFooter.privacy.${locale}`)}
        </Text>
      </VStack>

      <VStack
        as='section'
        align={'stretch'}
        spacing={2}
        bgColor='light.500'
        // p={8}
        rounded='md'
        mt='6'
      >
        <VStack
          align={'stretch'}
          bgColor='light.500'
          px='6'
          py='4'
          rounded='md'
          spacing={4}
          id='comments'
        >
          <Heading
            pt='3'
            as='h2'
            fontSize={'h2'}
            data-toc='exclude'
            display={'flex'}
            alignItems='center'
          >
            <Icon as={Message} color='dark.600' mr={1} />
            <Text as='span'>
              {T(`general.metaFooter.your_opinion.${locale}`)}
            </Text>
          </Heading>
          <Text color='dark.600'>
            {T(`general.metaFooter.take_care.${locale}`)}
          </Text>
        </VStack>

        {/* TODO clean this code  */}
        <Box display={'none'}>
          {SSRComments?.map((comment, index) => {
            return (
              <Text key={index}>{htmlParser(comment?.content?.rendered)}</Text>
            );
          })}
        </Box>

        <Box bgColor='#fdfcf985' p='4' pt='6'>
          <CommentsList
            {...{
              answerId: id,
              blogId: id,
              comments,
              SSRComments,
              commentsRefetch
            }}
          />
        </Box>
      </VStack>
    </>
  );
};
