import { Box, Text, VStack } from '@chakra-ui/react';
import {
  Form,
  HiddenInput,
  SubmitButton,
  Textarea
} from 'components/forms-elements';
import { useToast } from 'hooks';
import { validationRules } from 'utls';

import { useAuth } from 'context';
import { FormikConfig } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { mutationFn } from 'utls';
import * as Yup from 'yup';
// import RichTextField from 'components/forms-elements/SunEditor/RichTextField';
import axios from 'axios';
import { useSession } from 'next-auth/react';

const validationSchema = Yup.object({
  id: validationRules.stringOptional.optional(),
  content: validationRules.string.min(15, 'يجب أن يكون ١٥ حرف على الأقل')
});

interface Props {
  id: string;
  content?: string;
  editAnswer?: boolean;
  setEditAnswer?: (editAnswer) => void;
  refetch?: () => void;
}

export const EditAnswerForm: React.FC<Props> = ({
  id,
  content,
  setEditAnswer,
  editAnswer,
  refetch,
  type
}) => {
  const toast = useToast();
  const { data: session } = useSession();
  // const { token } = useAuth();
  // const { mutateAsync: mutateEdit } = useMutation(
  //   (params: any) => mutationFn({ name: `comments/${id}`, params, token:session?.accessToken }),
  //   {
  //     onSuccess: ({ data, params }) => {
  //       setEditAnswer(false);

  //       refetch();
  //       toast({
  //         title: 'عمليه ناجحه',
  //         description: 'تم التعديل بنجاح',
  //         status: 'success'
  //       });
  //     },
  //     onError: error => {
  //       toast({
  //         title: 'خطأ',
  //         description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
  //         status: 'error'
  //       });
  //     }
  //   }
  // );

  const headers = {
    Authorization: `Bearer ${session?.accessToken}`
  };
  // const { mutateAsync: mutateEditComment } = useMutation(
  //   (params: any) => {
  //     return axios.post(
  //       `https://staging.arageek.com/wp-json/wp/v2/comments/${id}`,
  //       {
  //         headers: headers
  //       }
  //     );
  //   },
  //   {
  //     onSuccess: ({ data }) => {
  //       console.log(data, 'data');
  //       setEditAnswer(false);
  //       toast({
  //         title: 'عمليه ناجحه',
  //         description: 'تم التعديل بنجاح',
  //         status: 'success'
  //       });
  //       refetch();
  //     },
  //     onError: error => {
  //       toast({
  //         title: 'خطأ',
  //         description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
  //         status: 'error'
  //       });
  //       console.log(error, 'error');
  //     }
  //   }
  // );

  const { mutateAsync: mutateEditComment } = useMutation(
    (params: any) => {
      return axios.post(
        `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/wp/v2/comments/${id}`,
        {},
        {
          headers: headers
        }
      );
    },
    {
      onSuccess: ({ data }) => {
        console.log(data, 'data');
        toast({
          title: 'عمليه ناجحه',
          description: 'تم التعديل بنجاح',
          status: 'success'
        });
        refetch();
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      mutateEditComment(values);
      resetForm();
    } catch (error) {
      resetForm();
    }
  };

  return (
    <Form
      initialValues={{
        id: id,
        content: content
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <VStack align={'stretch'} spacing={4}>
        <HiddenInput name='id' />
        <Textarea name='content' label='نص الاجابه ...' height={40} />
        {/* <Box dir='ltr'>
          <RichTextField
            defaultValue={content ?? null}
            id={'content'}
            name={'content'}
          />
        </Box> */}
      </VStack>

      <VStack align={'stretch'} spacing={8} mt='10'>
        {/* <Text color={'dark.600'} fontSize='sm'>
          احرص على أن يكون سؤالك واضحاََ, قصيراََ ومباشراََ, موافقاََ لشروط
          النشر في المجتمع, وخالِِ من الأخطاء الإملائية و النحوية.
        </Text> */}

        <SubmitButton>تعديل الاجابه</SubmitButton>
      </VStack>
    </Form>
  );
};
