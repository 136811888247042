import { Button, Box, useToast, Icon, VStack, Text } from '@chakra-ui/react';
import { useAuth } from 'context';
import useTranslation from 'hooks/useTranslation';
import { Facebook } from 'iconsax-react';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { BsFacebook } from 'react-icons/bs';

export const FacebookAuthButton = ({ title, type }) => {
  const toast = useToast();
  const { mutateFacebookAuth } = useAuth();
  const { T, locale } = useTranslation();

  const router = useRouter();
  console.log(router.asPath, 'faceface');
  const [backUrl, setBackUrl] = useState('');
  useEffect(() => {
    if (router.asPath) setBackUrl(router.asPath);
  }, [router.asPath]);

  const responseFacebook = async response => {
    try {
      const access_token = String(response.accessToken);
      console.log(access_token, 'access_tokenaccess_token');

      await mutateFacebookAuth({ access_token });

      // await signIn('facebook', {
      //   callbackUrl: `/`,
      //   redirect: false
      // });

      await signIn('facebook', {
        // callbackUrl: '/',
        // redirect: false

        redirect: true,
        callbackUrl: backUrl
      });

      // console.log(response, 'handleLoginhandleLogin');

      // if (response?.error) console.log(response?.error);
      // if (response) console.log(response, 'resssss');
    } catch (e) {
      console.error(e);
    }

    if (response.status === 'unknown') {
      toast({
        title: T(`general.toastMessages.error.${locale}`),
        description: T(`general.toastMessages.error_message.${locale}`),
        status: 'error'
      });
    }
  };

  return (
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      fields='name,email,picture'
      autoLoad={false}
      callback={responseFacebook}
      render={renderProps => (
        <VStack align={'center'} spacing='2'>
          <Button
            variant={'none'}
            // backgroundColor={'dark.100'}
            color='#fff'
            sx={{
              span: {
                marginInlineEnd: '0rem'
              }
            }}
            leftIcon={<Icon as={BsFacebook} fontSize='18px' />}
            onClick={renderProps.onClick}
            colorScheme='blackAlpha'
            backgroundColor={'black'}
            width='40px'
            height={'40px'}
          />

          <Text>{title}</Text>
        </VStack>
      )}
    />
  );
};
