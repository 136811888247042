import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Heading,
  LinkBox,
  Text
} from '@chakra-ui/react';
import { Image } from 'components/shared';
import { NextChakraLinkOverlay } from 'components/wrappers';
import { getYear, htmlParser } from 'utls';

export interface BioItemProps {
  link: string;
  acf: any;
  _embedded: any;
  thumb: string;
  title: string;
  thumbnail: string;
  death_date: string;
  birth_date: string;
}

export const BioItem: React.FC<BioItemProps> = ({
  link,
  acf,
  _embedded,
  thumb,
  title,
  thumbnail,
  death_date,
  birth_date
}) => {
  const featured_media = _embedded?.['wp:featuredmedia']?.[0] ?? {
    url: thumbnail ?? thumb
  };

  const isDead = acf?.person_deathdate
    ? Boolean(acf?.person_deathdate)
    : death_date
    ? Boolean(death_date)
    : false;

  return (
    <AspectRatio ratio={3 / 4}>
      <LinkBox
        role={'group'}
        as='article'
        w='full'
        bgColor={'dark.700'}
        rounded={'md'}
        boxShadow={'lg'}
        overflow='hidden'
      >
        <Box pos={'relative'} w='full' alignSelf={'flex-end'}>
          <Image
            ratio={1}
            image={featured_media}
            filter={isDead ? 'grayscale(1)' : undefined}
            sizes={'(min-width: 62em) 17vw, 50vw'}
          />
          {isDead && (
            <Flex
              pos={'absolute'}
              inset={0}
              p={4}
              color='white'
              justify={'center'}
              align='flex-end'
              fontSize='sm'
            >
              <Text>
                {getYear(acf?.person_birthdate ?? birth_date)} -{' '}
                {getYear(acf?.person_deathdate ?? death_date)}
              </Text>
            </Flex>
          )}
        </Box>
        <Box
          pos={'absolute'}
          top={0}
          insetX={0}
          w='full'
          h='100%'
          flexGrow={1}
          bgGradient='linear(to-t, transparent 0%,transparent 50%, dark.700 75%)'
          zIndex={1}
        >
          <Center p={4}>
            <Heading
              as='span'
              transitionProperty={'all'}
              transitionDuration='slower'
              transitionTimingFunction='ease-in-out'
              size='sm'
              textAlign={'center'}
              fontWeight='medium'
              color={'dark.300'}
              _groupHover={{
                color: 'white'
              }}
            >
              <NextChakraLinkOverlay
                display={'flex'}
                flexDir='column'
                href={link}
              >
                {!acf ? (
                  <Box>
                    {title?.rendered ? (
                      <Text
                        as='span'
                        dangerouslySetInnerHTML={{ __html: title?.rendered }}
                      />
                    ) : (
                      <Text
                        lineHeight={'1.5'}
                        fontWeight={'500'}
                        as='span'
                        noOfLines={2}
                      >
                        {htmlParser(title)}
                      </Text>
                    )}
                  </Box>
                ) : (
                  ''
                )}

                <Box>
                  {(acf?.person_nickname_ar || acf?.person_name_ar) && (
                    <Text as='span'>
                      {acf?.person_nickname_ar || acf?.person_name_ar}
                    </Text>
                  )}
                  {(acf?.person_nickname_en || acf?.person_name_en) && (
                    <Text as='span'>
                      {acf?.person_nickname_en || acf?.person_name_en}
                    </Text>
                  )}
                </Box>
              </NextChakraLinkOverlay>
            </Heading>
          </Center>
        </Box>
      </LinkBox>
    </AspectRatio>
  );
};
