import { useToast } from 'hooks';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCookie } from 'react-use';
import { mutationFn, setCookieFn, USER_TOKEN_KEY } from 'utls';
import axios from 'redaxios';

import { hideLoginModal, useLayoutContext } from './layout';
import { signOut, useSession } from 'next-auth/react';
import useTranslation from 'hooks/useTranslation';
import Cookies from 'js-cookie';
export const AuthContext = createContext({});

const VALIDATE_LOGIN_URL = '/login/validate';

const REGISTER_CREATE_PROFILE = '/login/create-profile';

const HOME_PAGE = '/';

export const AuthProvider: React.FC = ({ children }) => {
  const { data: session } = useSession();
  const { T, locale } = useTranslation();
  const toast = useToast();
  const { push } = useRouter();
  const [authEmail, setAuthEmail, removeAuthEmail] = useCookie(
    'arageek-magazine-auth-email'
  );
  const { dispatch, state } = useLayoutContext();

  const [currentUser, setCurrentUser] = useState({});
  const [token, setToken, removeToken] = useCookie('next-auth.session-token');
  // const [token, setToken, removeToken] = useCookie(USER_TOKEN_KEY);
  const [tokenValue, setTokenValue] = useState('');
  // console.log(token, 'token');
  const getUser = async () => {
    const { data } = await axios.get(
      `https://${process.env.NEXT_PUBLIC_BACKEND_URL}/wp-json/arageek/v1/get-user`,
      {
        headers: {
          ...(session?.accessToken && {
            Authorization: `Bearer ${session?.accessToken}`
          })
        }
      }
    );

    return setCurrentUser(data.data);
  };

  const {
    isError,
    isLoading,
    data: userData,
    refetch: refetchUser
  } = useQuery(['get-user', getUser], {
    enabled: Boolean(session?.accessToken)
  });
  // console.log(userData, 'currentUsercurrentUser');
  // useEffect(() => {
  //   if (window.sessionStorage.getItem('arageek-token'))
  //     setTokenValue(window.sessionStorage.getItem('arageek-token'));
  // }, [window.sessionStorage.getItem('arageek-token')]);

  useEffect(() => {
    if (session?.accessToken) getUser();
  }, [session?.accessToken]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     setTokenValue(sessionStorage.getItem('arageek-token'));
  //   }
  // });

  // console.log(token, 'token');
  console.log(token, 'token');

  const { mutateAsync: mutateRequestAuth } = useMutation(
    params =>
      mutationFn({
        name: params?.type === 'login' ? `request_auth` : `register`,
        params,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data, params }) => {
        console.log(data, 'data');
        setAuthEmail(params?.email);
        Cookies.set('arageek-magazine-auth-email', params?.email);
        dispatch(hideLoginModal());
        if (
          !params.resend &&
          data?.register_type === 'existing' &&
          params.type === 'register'
        ) {
          toast({
            title: T(`general.toastMessages.already_exists.${locale}`),
            description: '',
            status: 'warning'
          });
        }
        if (data?.register_type !== 'existing' && params.type === 'register') {
          setAuthEmail(params?.email);
          // push(VALIDATE_LOGIN_URL);

          push(`${VALIDATE_LOGIN_URL}?url=${params.backUrl}`);
          toast({
            title: T(`general.toastMessages.confirmation_code.${locale}`),
            description: '',
            status: 'success'
          });
        }

        if (!params.resend && params.type === 'login') {
          setAuthEmail(params?.email);
          // push(VALIDATE_LOGIN_URL);
          push(`${VALIDATE_LOGIN_URL}?url=${params.backUrl}`);
          toast({
            title: T(`general.toastMessages.confirmation_code.${locale}`),
            description: '',
            status: 'success'
          });
        }
        if (params.resend) {
          toast({
            title: T(`general.toastMessages.confirmation_code.${locale}`),
            description: '',
            status: 'success'
          });
        }
      },
      onError: error => {
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description: T(`general.toastMessages.email_not_found.${locale}`),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateVerifyAuth } = useMutation(
    data =>
      mutationFn({
        name: `verify_auth`,
        params: { email: authEmail, ...data },
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: ({ data, params }) => {
        console.log({ data, params }, ', "data?.authdata?.auth"');
        const { token } = data?.auth;
        setCookieFn(USER_TOKEN_KEY, token, 4);

        sessionStorage.setItem('arageek-token', token);

        // setCookie(USER_TOKEN_KEY, token, {
        //   expires: new Date(),
        //   httpOnly: true,
        //   secure: true
        // });

        removeAuthEmail();
        if (!data?.auth?.firstName || !data?.auth?.lastName) {
          push(REGISTER_CREATE_PROFILE);
        } else {
          push(HOME_PAGE);
        }
      },
      onError: ({ data }) => {
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description: T(`general.toastMessages.code_error.${locale}`),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateUpdateUser } = useMutation(
    params =>
      mutationFn({
        name: `edit-user`,
        params,
        namespace: 'arageek/v1',
        token: tokenValue
      }),
    {
      onSuccess: ({ data, params }) => {
        // push(VALIDATE_LOGIN_URL);
        push(HOME_PAGE);
        // push('/');
      },
      onError: ({ data }) => {
        console.log(data, 'error');
        toast({
          title: T(`general.toastMessages.error.${locale}`),
          description:
            data?.message === 'Username already exists'
              ? T(`general.toastMessages.username_error.${locale}`)
              : T(`general.toastMessages.try_again.${locale}`),
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateGoogleAuth } = useMutation(
    params =>
      mutationFn({
        name: `google-login`,
        params: params,
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: (data, params) => {
        console.log('google data: ', params);
        const token = String(data.data.auth.token);
        setCookieFn(USER_TOKEN_KEY, token, 4);

        sessionStorage.setItem('arageek-token', token);

        // setCookie(USER_TOKEN_KEY, token, {
        //   expires: new Date(),
        //   httpOnly: true,
        //   secure: true
        // });
        // Cookies.set(USER_TOKEN_KEY, token, {
        //   expires: new Date(),
        //   httpOnly: true,
        //   secure: true
        // });
        dispatch(hideLoginModal());

        push(HOME_PAGE);
      }
    }
  );

  const { mutateAsync: mutateFacebookAuth } = useMutation(
    ({ access_token }) =>
      mutationFn({
        name: `facebook-login`,
        params: { access_token },
        namespace: 'arageek/v1'
      }),
    {
      onSuccess: (data, params) => {
        console.log('facebook data: ', params);
        const token = String(data.data.auth.token);

        setCookieFn(USER_TOKEN_KEY, token, 4);

        sessionStorage.setItem('arageek-token', token);

        // setCookie(USER_TOKEN_KEY, token, {
        //   expires: new Date(),
        //   httpOnly: true,
        //   secure: true
        // });
        // Cookies.set(USER_TOKEN_KEY, token, {
        //   expires: new Date(),
        //   httpOnly: true,
        //   secure: true
        // });
        dispatch(hideLoginModal());
        push(HOME_PAGE);
      }
    }
  );

  const goToLoginPage = () => {
    window.location.href = '/';
  };

  const mutateLogout = async () => {
    // removeToken();
    await signOut();
    toast({
      title: T(`general.toastMessages.signed_out_successfully.${locale}`),
      description: '',
      status: 'success'
    });
    goToLoginPage();
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        mutateRequestAuth,
        mutateVerifyAuth,
        mutateLogout,
        mutateGoogleAuth,
        mutateFacebookAuth,
        currentUser,
        mutateUpdateUser,
        isLoggedIn: Boolean(tokenValue)
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
