import { createIcon } from '@chakra-ui/icon';

export const StarDoodle = createIcon({
	displayName: 'StarDoodle',
	path: (
		<g fill='currentColor'>
			<path
				fillRule='evenodd'
				d='M13.598 11.84C10.756 9.65 8.452 7.044 6.722 4.01c-.346-.605-1.152-.836-1.804-.512-.646.324-.892 1.079-.546 1.69 1.904 3.322 4.42 6.177 7.535 8.578.566.436 1.411.361 1.877-.169.466-.536.38-1.321-.186-1.758Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M12.083 3.317c.146 2.519.28 5.037.446 7.556.046.686.679 1.21 1.411 1.166.732-.044 1.291-.636 1.245-1.322-.167-2.512-.3-5.024-.446-7.543-.04-.684-.673-1.21-1.405-1.172-.732.039-1.298.628-1.251 1.315ZM21.965 3.827c-1.704 2.407-3.634 4.626-5.578 6.87a1.188 1.188 0 0 0 .193 1.752c.572.436 1.411.355 1.87-.181 1.997-2.3 3.981-4.588 5.738-7.063.406-.574.233-1.347-.38-1.727-.605-.38-1.437-.224-1.843.35Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M28.567 12.774c-4.62.194-9.526.2-14.145-.074-.732-.038-1.365.48-1.411 1.171-.047.686.512 1.278 1.245 1.322 4.712.274 9.718.268 14.43.075.733-.031 1.305-.617 1.272-1.303-.034-.686-.66-1.222-1.391-1.19Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M11.904 13.566c2.85 3.267 5.798 6.471 8.44 9.887.433.555 1.265.68 1.864.274.593-.405.726-1.184.293-1.739-2.675-3.453-5.658-6.695-8.54-10a1.393 1.393 0 0 0-1.87-.174c-.573.437-.653 1.222-.187 1.752Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M12.767 14.514c.746 3.404.952 6.658 1.005 10.105.007.686.613 1.235 1.345 1.228.739-.012 1.325-.573 1.311-1.265-.053-3.61-.273-7.007-1.052-10.573-.153-.673-.851-1.11-1.577-.966-.719.137-1.185.798-1.032 1.47Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M12.888 14.24c-2.156 4.04-3.268 8.528-5.578 12.48-.353.599-.113 1.36.533 1.69.645.33 1.45.105 1.804-.493 2.323-3.977 3.448-8.49 5.624-12.562.326-.617.06-1.365-.592-1.677-.66-.305-1.458-.056-1.79.562Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M14.074 13.323c-4.293.71-8.58 1.035-12.9 1.515-.732.08-1.251.698-1.165 1.384.087.685.746 1.172 1.478 1.09 4.367-.48 8.707-.816 13.053-1.533.72-.124 1.205-.773 1.079-1.446-.133-.68-.82-1.135-1.545-1.01Z'
				clipRule='evenodd'
			/>
			<path
				fillRule='evenodd'
				d='M17.061 11.428a27.329 27.329 0 0 1-2.59-.212c-.732-.1-1.404.374-1.51 1.054-.107.68.399 1.315 1.125 1.415.958.13 1.883.193 2.855.23.732.032 1.358-.498 1.385-1.19.033-.686-.533-1.266-1.265-1.297Z'
				clipRule='evenodd'
			/>
		</g>
	),
	viewBox: '0 0 30 30'
});
