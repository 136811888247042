import { getItemsData } from 'utls';

const taxonomies = {
  person_zodiac: 'bio',
  person_nationality: 'bio',
  person_country_of_birth: 'bio',
  person_city_of_birth: 'bio',
  person_job: 'bio',
  arts_cat: 'art',
  edu_cat: 'edu',
  encyclopedia_cat: 'encyclopedia',
  ibda3world_cat: 'ibda3world',
  listat_cat: 'listat',
  news_cat: 'news',
  tech_cat: 'tech',
  tv_cat: 'tv',
  category: 'posts'
};

export const getTaxParams = (data: any) => {
  const { id, taxonomy } = data;
  console.log(data, 'datadata');
  console.log(taxonomy, 'datadata');

  const name = taxonomies?.[taxonomy];
  console.log(name, 'namename');

  const per_page = name === 'bio' ? 24 : 25;

  const params = {
    [taxonomy === 'category' ? 'categories' : taxonomy]: [id],
    per_page
  };
  console.log(params, 'paramsparams');
  return { name, params };
};

const getFns = async (data: any) => {
  const { name, params } = getTaxParams(data);
  console.log(data, 'getTaxParamsdatadata');

  const catInitialData = await getItemsData(name, params);
  console.log(catInitialData, 'catInitialData');

  return { catInitialData };
};

export default getFns;
