const signs = [
  'aries',
  'taurus',
  'gemini',
  'cancer',
  'leo',
  'Virgo',
  'libra',
  'scorpio',
  'sagittarius',
  'capricorn',
  'aquarius',
  'pisces',
];

const zodiacArabicNames = {
  aries: 'الحمل',
  taurus: 'الثور',
  gemini: 'الجوزاء',
  cancer: 'السرطان',
  leo: 'الأسد',
  Virgo: 'العذراء',
  libra: 'الميزان',
  scorpio: 'العقرب',
  sagittarius: 'القوس',
  capricorn: 'الجدي',
  aquarius: 'الدلو',
  pisces: 'الحوت',
};

const zodiacID = {
  aries: '3007',
  taurus: '3050',
  gemini: '3071',
  cancer: '3015',
  leo: '3036',
  Virgo: '3114',
  libra: '3029',
  scorpio: '3038',
  sagittarius: '3023',
  capricorn: '3063',
  aquarius: '3058',
  pisces: '3082',
};

export const getTodayZodiacName = (): string => {
  const sign =
    Number(
      new Intl.DateTimeFormat('fr-TN-u-ca-persian', {
        month: 'numeric',
      }).format(Date.now()),
    ) - 1;

  return signs[sign];
};

export const getTodayZodiacArabicName = (): string => {
  const zodiac = getTodayZodiacName();

  return zodiacArabicNames[zodiac];
};

export const getTodayZodiacId = (): string => {
  const zodiac = getTodayZodiacName();

  return zodiacID[zodiac];
};
