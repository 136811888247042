import { useAuth } from 'context';
import { useMutation } from '@tanstack/react-query';
import { mutationFn } from 'utls';
import { useCurrentUser, useToast } from './';

export interface UseReaction {
  onClick: () => void;
  isLoading: boolean;
}

export const useReaction = (
  actionType: 'upvote' | 'downvote',
  ref_id: string | number,
  ref_type: 'question' | 'answer' | 'comment',
  callback?: () => void
): UseReaction => {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const { token } = useAuth();

  const { mutateAsync: mutateDoReaction, isLoading } = useMutation(
    params =>
      mutationFn({
        name: `do-reaction`,
        params,
        token
      }),
    {
      onSuccess: ({ data, params }) => {
        callback?.({ ...data?.data, actionType });
        console.log(data, 'datadata');
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  const onClick = async () => {
    if (!ref_id || !ref_type) {
      return;
    }
    if (!currentUser) {
      toast({
        title: 'تنبيه',
        description: 'يرجي تسجيل الدخول اولا',
        status: 'warning'
      });
    }
    await mutateDoReaction({
      type: actionType,
      ref_id,
      ref_type
    });
  };

  return {
    onClick,
    isLoading
  };
};
