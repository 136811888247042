import { Box, Button, Center, Collapse, useDisclosure, VStack } from '@chakra-ui/react';
import { htmlParser } from 'utls';

interface Props {
  content: string;
}

export const AnswerContent: React.FC<Props> = ({ content }) => {
  const { isOpen, onOpen } = useDisclosure();

  console.log(content, 'contentcontent');

  return (
    <Box pos={'relative'}>
      <Collapse startingHeight={100} in={isOpen}>
        <VStack py={4} align={'stretch'}>
          {htmlParser(content)}
        </VStack>
      </Collapse>

      {!isOpen && content.length > 50 && (
        <Center
          py={1}
          pos={'absolute'}
          insetX={0}
          bottom={0}
          zIndex={1}
          bgGradient="linear(to-t, white 0%, whiteAlpha.800 80%,transparent 100%)">
          <Button size="sm" onClick={onOpen} variant="ghost">
            أكمل القراءة
          </Button>
        </Center>
      )}
    </Box>
  );
};
