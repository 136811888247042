import { useAuth } from 'context';
import { useToast } from 'hooks';
// import { useMutation, useQuery } from 'react-query';
import { mutationFn } from 'utls';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

interface UseComments {
  refetch: () => void;
  mutateAddComment: (params) => void;
  mutateDeleteComment: (params) => void;
  mutateEditComment: (params) => void;
  comments: Comment[];
  isLoading: boolean;
}

export const useComments = (object_id: string): UseComments => {
  const toast = useToast();
  // const { token } = useAuth();

  const { data: session } = useSession();
  const { refetch, data, isLoading } = useQuery(
    [
      'comments',
      { post: object_id, page: 1, per_page: 100 },
      session?.accessToken
    ]
    // {
    //   enabled: !!false
    // }
  );

  const comments = data?.data ?? [];

  // console.log(comments, 'commentscomments');
  // console.log(data, 'commentscomments');

  const { mutateAsync: mutateAddComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `comments`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: ({ data, params }) => {
        console.log(data, 'data');
        refetch();
        toast({
          title: 'عمليه ناجحه',
          description: 'تم إضافة التعليق',
          status: 'success'
        });
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateDeleteComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `delete-comment`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: ({ data, params }) => {
        console.log(data, 'data');
        refetch?.();
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  const { mutateAsync: mutateEditComment } = useMutation(
    (params: any) =>
      mutationFn({
        name: `edit-comment`,
        params,
        token: session?.accessToken,
        namespace: 'wp/v2'
      }),
    {
      onSuccess: ({ data, params }) => {
        console.log(data, 'data');
        refetch();
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  return {
    refetch,
    isLoading,
    comments,
    mutateAddComment,
    mutateDeleteComment,
    mutateEditComment
  };
};
