import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  Icon,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { ArrowLeft2, Home } from 'iconsax-react';
import { useMedia } from 'react-use';
import { NextChakraIconButton, NextChakraLink } from '../wrappers';
import useTranslation from 'hooks/useTranslation';

export const Breadcrumb: React.FC = ({ breadcrumb }: any) => {
  if (!Array.isArray(breadcrumb)) {
    return null;
  }
  // const breadcrumbSize = useBreakpointValue(
  //   { base: true, sm: false },
  //   { ssr: false }
  // );

  const isMobile = useMedia('(max-width: 700px)', true);
  const { locale } = useTranslation();
  return (
    <>
      <ChakraBreadcrumb
        width='auto'
        overflowX='scroll'
        sx={{
          ol: {
            display: 'flex',
            width: isMobile ? '120vh' : '100%',
            overflowX: 'auto'
            // flexWrap: 'wrap'
          },
          scrollbarColor: 'white white',
          '&::-webkit-scrollbar': {
            width: '3',
            display: 'none'
          },

          '&::-webkit-scrollbar-thumb': {
            bgColor: '#bdbaba',
            borderRadius: 'full',
            borderColor: 'white',
            borderWidth: '3',
            borderStyle: 'solid'
          }
        }}
        separator={<Icon display='block' as={ArrowLeft2} />}
      >
        {breadcrumb
          // ?.filter((_, index) => index < items.length - 1)
          ?.map(({ position, name, item = '/' }, index) => {
            const isLastItem = index === breadcrumb.length - 1;
            // console.log(name.length, 'namename');
            return (
              <BreadcrumbItem
                key={position}
                isCurrentPage={isLastItem}
                isLastChild={isLastItem}
                {...(isLastItem && {
                  overflow: 'hidden'
                })}
                // flexWrap={{ base: 'nowrap', sm: 'nowrap', md: 'wrap' }}
                // flexWrap='wrap'
                // flex={position === 3 && 'none'}
                sx={{
                  span: {
                    transform: locale === 'en' ? 'rotate(180deg)' : ''
                  }
                }}
              >
                {position === 1 ? (
                  <NextChakraIconButton
                    variant={'ghost'}
                    icon={<Icon as={Home} />}
                    href={item}
                    aria-label={name}
                  />
                ) : (
                  <NextChakraLink
                    {...(isLastItem && {
                      'aria-current': 'page',
                      pointerEvents: 'none',
                      _before: { content: 'none' },
                      _hover: {
                        cursor: 'default'
                      }
                    })}
                    href={item}
                    // noOfLines={isLastItem && 1}
                    noOfLines={1}
                    // width={isLastItem && '95%'}
                  >
                    <Text
                      width={isLastItem ? '90%' : '100%'}
                      as='span'
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                  </NextChakraLink>
                )}
              </BreadcrumbItem>
            );
          })}
      </ChakraBreadcrumb>
    </>
  );
};
