import { AvatarProps, HStack } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import {
  CommentTextarea,
  Form,
  HiddenInput,
  SubmitButton
} from 'components/forms-elements';
import { FormikConfig } from 'formik';
import { useComments } from 'hooks';
import { validationRules } from 'utls';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  parent_comment_id: validationRules.stringOptional.optional(),
  object_id: validationRules.stringOptional.optional(),
  // content: validationRules.string.min(15, 'يجب أن يكون ١٥ حرف على الأقل'),

  content: validationRules.stringOptional
});

interface Props {
  answerId: string;

  parentId?: string;
  itemsize: AvatarProps['size'];
}

export const AddBlogCommentForm: React.FC<Props> = ({
  answerId,
  parentId,
  itemsize,
  blogId
}) => {
  const { mutateAddComment } = useComments(answerId);
  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    { resetForm }
  ) => {
    try {
      if (!values.parent) {
        delete values.parent;
      }
      await mutateAddComment(values);
    } catch (error) {}
    resetForm();
  };

  return (
    <Box w='full'>
      <Form
        initialValues={{
          // object_id: answerId,
          parent: parentId,
          post: blogId,
          content: ''
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <HiddenInput name='object_id' />
        <CommentTextarea name='content' size={itemsize} />
      </Form>
    </Box>
  );
};
