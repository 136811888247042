import type { ButtonProps } from '@chakra-ui/react';
import { Button, Icon, IconButton } from '@chakra-ui/react';
import { ArrowDown, ArrowUp } from 'assets';
import { NextChakraButton, NextChakraButtonProps } from 'components/wrappers';
// import { useReaction } from 'hooks';
import {
  Brodcast,
  DirectDown,
  DirectUp,
  Magicpen,
  MessageText1
} from 'iconsax-react';
import { approximateNumber } from 'utls';
import { ImArrowUp, ImArrowDown } from 'react-icons/im';
import { TbArrowBigDown, TbArrowBigTop } from 'react-icons/tb';
import dynamic from 'next/dynamic';
import { useReaction } from 'hooks/useReaction';
import { CurrentVote } from 'types/questions';
// const NextChakraButton = dynamic(() => import('components/wrappers'));
const defaultProps: ButtonProps = {
  size: 'md',
  variant: 'ghost'
};

interface ExtraProps {
  count?: number | string;
}

interface ReactionProps {
  refId: string | number;
  refType: 'question' | 'answer' | 'comment';
  currentVote: CurrentVote;
  callback: () => void;
}

export const AddAnswerButton: React.FC<NextChakraButtonProps> = ({
  ...rest
}) => {
  return (
    <NextChakraButton
      leftIcon={<Icon as={Magicpen} />}
      {...defaultProps}
      {...rest}
    >
      إجابة
    </NextChakraButton>
  );
};

export const AddCommentButton: React.FC<ButtonProps & ExtraProps> = ({
  count,
  ...rest
}) => {
  const hasCount = count && count > 0;
  if (!hasCount) {
    return (
      <IconButton
        aria-label='كتابة تعليق'
        icon={<Icon as={MessageText1} />}
        {...defaultProps}
        {...rest}
        color={'dark.600'}
        backgroundColor={'#F1EFE2 !important'}
      />
    );
  }

  return (
    <Button
      leftIcon={<Icon as={MessageText1} />}
      {...defaultProps}
      {...rest}
      color={'dark.600'}
      backgroundColor={'#F1EFE2 !important'}
    >
      {approximateNumber(count)}
    </Button>
  );
};

export const FollowQuestionButton: React.FC<ButtonProps & ExtraProps> = ({
  count,
  ...rest
}) => {
  const hasCount = count;

  if (!hasCount) {
    return (
      <IconButton
        aria-label='متابعة'
        leftIcon={<Icon as={Brodcast} />}
        {...defaultProps}
        {...rest}
      />
    );
  }

  return (
    <Button leftIcon={<Icon as={Brodcast} />} {...defaultProps} {...rest}>
      متابعة . {approximateNumber(count)}
    </Button>
  );
};

export const UpVoteButton: React.FC<
  ButtonProps & ExtraProps & ReactionProps
> = ({ count, refId, refType, currentVote, callback, ...rest }) => {
  const { onClick, isLoading } = useReaction(
    'upvote',
    refId,
    refType,
    callback
  );

  const isActive = currentVote === 'upvote';

  const hasCount = count && count > 0;
  if (!hasCount) {
    return (
      <IconButton
        aria-label='أعجبني'
        icon={<TbArrowBigTop />}
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isLoading || isActive}
        isActive={isActive}
        {...defaultProps}
        {...rest}
        color='dark.600'
      />
    );
  }

  return (
    <Button
      leftIcon={isActive ? <ImArrowUp /> : <TbArrowBigTop fontSize={'22px'} />}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isLoading}
      isActive={isActive}
      {...defaultProps}
      {...rest}
      color={isActive ? 'black' : 'dark.600'}
      backgroundColor={'#F1EFE2 !important'}
    >
      {approximateNumber(count)}
    </Button>
  );
};

export const DownVoteButton: React.FC<
  ButtonProps & ReactionProps & ExtraProps
> = ({
  count,
  refId,
  refType,
  currentVote,
  callback,

  ...rest
}) => {
  const { onClick, isLoading } = useReaction(
    'downvote',
    refId,
    refType,
    callback
  );
  const isActive = currentVote === 'downvote';

  const hasCount = count && count > 0;

  if (!hasCount) {
    return (
      <IconButton
        aria-label='لم يعجبني'
        onClick={onClick}
        isLoading={isLoading}
        isDisabled={isLoading}
        isActive={isActive}
        icon={<TbArrowBigDown />}
        {...defaultProps}
        {...rest}
        color='dark.600'
      />
    );
  }

  return (
    <Button
      leftIcon={
        isActive ? <ImArrowDown /> : <TbArrowBigDown fontSize={'22px'} />
      }
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isLoading}
      isActive={isActive}
      {...defaultProps}
      {...rest}
      color={isActive ? 'black' : 'dark.600'}
      background={'#F1EFE2 !important'}
    >
      {approximateNumber(count)}
    </Button>
  );
};
