import { Providers } from 'context';
import 'focus-visible/dist/focus-visible';
import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';

// export function reportWebVitals(metric) {
//   console.log(metric, 'metricmetric');
// }

function App({ Component, pageProps }) {
  console.log({ pageProps });
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
        />
      </Head>
      <SessionProvider session={pageProps.session}>
        <Providers {...{ pageProps }}>
          <Component />
        </Providers>
      </SessionProvider>
    </>
  );
}

export default App;
