import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Heading
} from '@chakra-ui/react';

import RequestForm from 'components/forms/RequestForm';

export const RegisterModal = ({ isOpen, setIsOpen }) => {
  const onClose = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody py="8" px="12">
          <VStack align="stretch" spacing={8}>
            <VStack align={'stretch'} spacing="4" textAlign="center">
              <Heading as="h3" fontSize={'h2'}>
                {' '}
                إنشاء حساب
              </Heading>
              <Text color="dark.600"> أضف البريد الإلكتروني الخاص بك لتلقي رمز التحقق. </Text>
            </VStack>

            <RequestForm
              type="register"
              btnText="التالي"
              btnProps={{
                colorScheme: 'dark'
              }}></RequestForm>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
