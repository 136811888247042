import { VStack } from '@chakra-ui/react';

import { Form, Input, SubmitButton } from 'components/forms-elements';
import { useAuth } from 'context';
import { FormikConfig } from 'formik';
import useTranslation from 'hooks/useTranslation';
import { validationRules, validationRulesEn } from 'utls';
import * as Yup from 'yup';

const RequestForm = ({
  children,
  type,
  btnText,
  btnProps,
  setRegister,
  backUrl
}: any) => {
  const { T, locale } = useTranslation();
  const { mutateRequestAuth } = useAuth();
  const validationSchema = Yup.object({
    email: locale === 'ar' ? validationRules.email : validationRulesEn.email
  });

  const onSubmit: FormikConfig<any>['onSubmit'] = async (
    values,
    formikHelpers
  ) => {
    try {
      await mutateRequestAuth({ ...values, type, setRegister, backUrl });
    } catch (error) {
      console.log(error, 'errorerror');
    }
  };

  return (
    <Form
      initialValues={{
        email: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <VStack align={'center'} spacing={8}>
        <Input
          name='email'
          type='email'
          label={T(`general.forms.email.${locale}`)}
          placeholder='example@example.com'
          isRequired
          inputProps={{
            border: '0.5px solid #DDD',
            borderRadius: 'md',
            paddingInlineStart: '2',
            fontSize: 'md',
            boxShadow: 'unset !important',
            backgroundColor: 'dark.100'
          }}
        />

        <SubmitButton w={'full'} {...btnProps}>
          {btnText}
        </SubmitButton>
        {children}
      </VStack>
    </Form>
  );
};

export default RequestForm;
