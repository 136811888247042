import { ChakraProvider } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import theme from 'configs/chakraTheme';
import { AuthProvider, PageProvider } from 'context';
import NextNProgress from 'nextjs-progressbar';
import { defaultQueryFn } from 'utls';
import { GoogleOAuthProvider } from '@react-oauth/google';

import dynamic from 'next/dynamic';
import { LayoutContextProvider } from './layout';
const CommentsProvider = dynamic(() => import('./CommentsProvider'));
const SiteConfigProvider = dynamic(() => import('./SiteConfigProvider'));
const RTLProvider = dynamic(() => import('./RTLProvider'));
const GoogleAnalytics = dynamic(
  () => import('../components/system/GoogleAnalytics')
);

const { ToastContainer } = createStandaloneToast();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn
    }
  }
});

export const Providers: React.FC = ({ pageProps, children }: any) => {
  return (
    <>
      {/* <PreflightCSS /> */}
      <NextNProgress
        color='#000'
        startPosition={0.3}
        stopDelayMs={200}
        height={1}
      />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ChakraProvider theme={theme}>
          <LayoutContextProvider>
            <AuthProvider>
              <RTLProvider>
                <CommentsProvider>
                  <PageProvider initialPageProps={pageProps}>
                    <GoogleAnalytics />
                    <SiteConfigProvider>
                      <GoogleOAuthProvider
                        clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                      >
                        {children}
                      </GoogleOAuthProvider>
                    </SiteConfigProvider>
                  </PageProvider>
                </CommentsProvider>
              </RTLProvider>
            </AuthProvider>
          </LayoutContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
};
