import {
  Button,
  Center,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useAuth } from 'context';
import { useToast, useUserAction } from 'hooks';
import useTranslation from 'hooks/useTranslation';
import { AddCircle, TickCircle } from 'iconsax-react';
import React, { useEffect } from 'react';
import { User } from 'types/users';

export const FollowButton: React.FC<
  Pick<User, 'index' | 'ID' | 'followed'>
> = ({ ID, followed: defaultFollowed, index, refetch }) => {
  const { T, locale } = useTranslation();
  const { onClick, isLoading, isFollowed } = useUserAction(ID, defaultFollowed);
  const { token } = useAuth();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (index <= 5) onClick();
  }, [index]);
  useEffect(() => {
    refetch?.();
  }, [isFollowed]);
  return (
    <React.Fragment>
      {isFollowed && (
        <Button
          onClick={() => {
            token !== null
              ? onOpen()
              : toast({
                  title: T(`general.toastMessages.alert.${locale}`),
                  description: T(`general.toastMessages.login_first.${locale}`),
                  status: 'warning'
                });
          }}
          size={'sm'}
          isLoading={isLoading}
          isDisabled={isLoading}
          colorScheme={'light'}
          _after={{
            content: '"متابع"'
          }}
          _hover={{
            _after: { content: '"إلغاء المتابعة"' },
            bg: '#F34E4E',
            color: '#fff',
            span: {
              display: 'none'
            }
          }}
          leftIcon={<Icon as={TickCircle} />}
        />
      )}
      {!isFollowed && (
        <Button
          onClick={() => {
            token !== null
              ? onClick()
              : toast({
                  title: T(`general.toastMessages.alert.${locale}`),
                  description: T(`general.toastMessages.login_first.${locale}`),
                  status: 'warning'
                });
          }}
          size={'sm'}
          isLoading={isLoading}
          isDisabled={isLoading}
          colorScheme={'light'}
          leftIcon={<Icon as={AddCircle} />}
        >
          تابع
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Center>تأكيد إلغاء المتابعة</Center>
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter>
            <Flex w='full' gap={8}>
              <Button
                colorScheme={'dark'}
                w='full'
                onClick={() => {
                  onClick();
                  onClose();
                }}
              >
                نعم
              </Button>
              <Button
                colorScheme={'light'}
                variant='outline'
                w='full'
                onClick={() => onClose()}
              >
                لا
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
