import { useAuth } from 'context';
import { useToast } from 'hooks';
import { useMutation } from '@tanstack/react-query';
import { useToggle } from 'react-use';
import { mutationFn } from 'utls';
import { useCurrentUser } from './';

export interface UseUserAction {
  onClick: () => void;
  isLoading: boolean;
  isFollowed: boolean;
}

export const useUserAction = (
  user_id: string | number,
  defaultIsFollowed: boolean
): UseUserAction => {
  const [isFollowed, toggle] = useToggle(defaultIsFollowed);

  const { token } = useAuth();
  const toast = useToast();
  const currentUser = useCurrentUser();

  const { mutateAsync: mutateUserAction, isLoading } = useMutation(
    ({ action, user_id }) =>
      mutationFn({
        name: `${action}-user`,
        params: { user_id },
        token
      }),
    {
      onSuccess: ({ data, params }) => {
        toggle();
      },
      onError: error => {
        toast({
          title: 'خطأ',
          description: 'خطأ من طرف السيرفر, يرجى المحاولة لاحقاً',
          status: 'error'
        });
      }
    }
  );

  const onClick = async () => {
    if (!user_id) {
      return;
    }

    if (!currentUser) {
      //TODO fire Toast to ask user to login
      return;
    }

    const action = isFollowed ? 'unfollow' : 'follow';

    await mutateUserAction({ user_id, action });
  };

  return {
    onClick,
    isLoading,
    isFollowed
  };
};
