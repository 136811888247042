// import { useAuth } from 'context';
// import { User } from 'types';
// import { getFullName } from 'utls';

import { useAuth } from 'context';
import { getFullName } from 'utls';

interface UserCurrentUser {
  slug: string;
  firstname: string;
  lastname: string;
  fullname: string;
  profileLink: string;
  refetchUser: () => void;
}

export const useCurrentUser = (): UserCurrentUser | false => {
  const { currentUser, refetchUser }: any = useAuth();

  if (!currentUser) {
    return false;
  }

  const { slug, firstname, lastname, role, email } = currentUser;

  const fullname = getFullName(firstname, lastname);
  const profileLink = `/profile/${slug}`;

  return {
    ...currentUser,
    slug,
    profileLink,
    fullname,
    refetchUser,
    email
  };
};
